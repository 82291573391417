import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Backgrounds = ({ value, onChange }) => {
	const t = LocalStorageHelper.get('translation')
	const [background, setBackground] = useState(value[0] || [])
	const [isLoading, setIsLoading] = useState(true)
	const style = {
		root: {
			marginBottom: '15px',
		},
		image: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			borderRadius: '15px',
			filter: 'saturate(1)',
		},
		item: {
			width: '120px',
			height: '120px',
			borderRadius: '15px',
			cursor: 'pointer',
			transition: 'all 0.3s',
			filter: 'grayscale(1)',
		},
		activeItem: {
			width: '120px',
			height: '120px',
			borderRadius: '15px',
			transition: 'all 0.3s',
			filter: 'drop-shadow(2px 4px 6px rgba(0,0,0,.5))',
			cursor: 'pointer',
		},
	}
	useEffect(() => {
		if (background) {
			if (onChange) {
				onChange(background)
			}
		}
	}, [background])
	useEffect(() => {
		if (background) {
			setTimeout(() => {
				setIsLoading(false)
			}, 1000)
		}
	}, [])
	return (
		<div style={style.root}>
			<Typography
				sx={{
					fontSize: '24px',
					marginBottom: '20px',
				}}
			>
				{t.globals.components.Backgrounds.chooseImage}
			</Typography>
			{
				isLoading
				? (
					<CircularProgress />
				)
				: (
					<Grid container spacing={2}>
						{
							value?.map((back, index) => (
								<Grid item md={1} key={index} onClick={() => setBackground(back)}>
									<div style={back === background ? style.activeItem : style.item}>
										<img src={back} alt={`image-${index}`} style={style.image}/>
									</div>
								</Grid>
							))
						}
					</Grid>
				)
			}
			<Typography
				sx={{
					fontSize: '24px',
					marginTop: '20px',
				}}
			>
				{t.globals.components.Backgrounds.data}
			</Typography>
		</div>
	)
}

export default Backgrounds
